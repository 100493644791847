<template>
    <section :id="cmsBlock.anchorTag" class="component-container white">
        <div class="tiles">
            <GraphicTile v-for="tile in cmsBlock.tiles" :key="tile.id" :tile="tile" />
        </div>
    </section>
</template>

<script>
import GraphicTile from '@/components/PageBuilder/Elements/GraphicTile'

export default {
    name: 'GraphicTiles',

    props: [
        'cmsBlock'
    ],

    components: {
        GraphicTile
    }
}
</script>

<style lang="scss" scoped>
.component-container {

    .tiles {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        max-width: $container-width;
        margin: 0 auto;
        padding: 0 getSpacing('padding-default-horizontal', 'desktop');

        @include breakpoint('tablet') {
            padding: 0 getSpacing('padding-default-horizontal', 'tablet');
            grid-template-columns: 1fr 1fr;
        }

        @include breakpoint('mobile') {
            grid-template-columns: 1fr;
            width: calc(100% - 2 * getSpacing('padding-default-horizontal', 'mobile'));
            padding: 0 getSpacing('padding-default-horizontal', 'mobile');
        }
    }
}
</style>
