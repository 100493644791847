<template>
    <div  v-if="this.tile.image.data && localize(this.tile.text)" class="white tile" v-on:click="toggle" :class="{open: open}">
        <div class="title" :class="{open: open}">
            <picture>
                <template v-for="[format, set] of Object.entries(srcsets)">
                    <source v-if="format !== originalFormat" :srcset="set" sizes="(max-width: 1100px) 100vw, 50vw" :type="'image/' + format.slice(1)">
                </template>
                <img v-if="originalFormat.slice(1) !== 'svg'" loading="lazy" decoding="async" :srcset="srcsets[originalFormat]" :type="'image/' + originalFormat.slice(1)" sizes="(max-width: 1100px) 80vw, 25vw" :alt="tile.image.data.attributes.alternativeText">
                <img v-if="originalFormat.slice(1) === 'svg'"
                            :src="getImageUrl(this.tile.image.data.attributes.url)" :type="'image/svg+xml'"
                            :alt="tile.image.data.attributes.alternativeText">
            </picture>
        </div>
        <div class="text" :class="{open: open}" v-html="md(localize(tile.text))"></div>
        <div class="plus-button-wrapper">
            <img class="plus" :class="{open: open}" src="@/assets/x.svg"/>
        </div>
    </div>
</template>

<script> 
    import { EventBus } from '@/event-bus'
    import { ToggleTextTile } from '@/events'

    export default {
        name: 'GraphicTile',

        props: [
            'tile'
        ],

        data() {
            return {
                open: false
            }
        },

        computed: {
            srcsets(){
                return this.getImageSrcsets(this.tile.image.data.attributes) || {}
            },
            
            hasWebp(){
                return '.webp' in this.srcsets;
            },

            originalFormat(){
                return this.tile.image.data.attributes.ext 
                /*for (const[format, _] of Object.entries(this.srcsets)) {
                    if (format !== '.webp') {
                        return format
                    }
                }
                return null*/
            },
        },

        eventListeners: [
            {
                event: ToggleTextTile,
                handler(event){
                    if(event.component != this){
                        this.open = false
                    }
                }
            }
        ],

        methods: {
            toggle() {
                EventBus.publish(new ToggleTextTile(this))
                if(window.innerWidth < 1600){
                    this.open = !this.open
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    $scrollbarPadding: 20px;

    @mixin openTile {
        //background: $timberGreen;
        //color:white;
    }

    @mixin openPlus {
        transform: rotate(0deg);

        &.dark {
            display: none;
        }

        &.light {
            display: block;
        }
    }

    @mixin openTitle {
        display: none;
    }

    @mixin openText {
        display: block;
    }

    .tile {
        background: white;
        height: calc(500px - 120px);
        width: calc(100% - 100px);
        position: relative;
        color: $timberGreen;
        overflow: hidden;
        padding: getSpacing('padding-default-vertical', 'desktop') getSpacing('padding-default-horizontal', 'desktop');
        padding-top: 40px;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        hyphens: auto;

        @include breakpoint('tablet') {
            padding-top: 30px;
            padding-left: 50px;
            padding-right: 50px;
            height: calc(500px - 110px);
            width: calc(100% - 100px);
            max-width: calc(100% - 100px) !important;
            overflow: hidden; 
        }
        @include breakpoint('mobile') {
            padding-top: 20px;
            padding-left: 30px;
            padding-right: 30px;
            height: calc(400px - 100px);
            width: calc(100% - 60px);
        }

        &:nth-child(odd) {
            background: $lightGrey;

            @include breakpoint('tablet') {
                background: white;
            }

            @include breakpoint('mobile') {
                background: $lightGrey;
            }
        }
        
        &.open {
            @include openTile;
        }

        .title {
            font-family: getFontFamily('textHeader');
            font-size: getFontSize('textHeader', 'desktop');
            color: $timberGreen;
            width: 100%;

            @include breakpoint('tablet') {
                font-size: getFontSize('textHeader', 'tablet');
            }
            @include breakpoint('mobile') {
                font-size: getFontSize('textHeader', 'mobile');
            }

            &.open {
                @include openTitle;
            }

            img {
                width: 100%;
                height: 150px;
                max-width: 333px;
                object-fit: contain;

                @include breakpoint('mobile') {
                    max-width: 250px;
                }
            }
        }

        .text {
            display: none;
            color: black;
            text-align: start;
            overflow-y: scroll;
            padding-top: 20px;

            ::v-deep p {
                color: black;

                font-family: getFontFamily('smallText');
                font-size: getFontSize('smallText', 'desktop');
                font-style: getFontStyle('smallText');
                color: $timberGreen;

                @include breakpoint('tablet') {
                    font-size: getFontSize('smallText', 'tablet');
                }
                @include breakpoint('mobile') {
                    font-size: getFontSize('smallText', 'mobile');
                }
            }
            

            &.open {
                @include openText;
            }
        }

        .plus-button-wrapper {
            margin-top: 10px;
            padding-right: $scrollbarPadding;
            position: absolute;
            bottom: 50px;
            width: calc(100% - 200px);
            display: flex;
            justify-content: center;

            .plus {
                transform: rotate(45deg);
                width: 20px;
                height: 20px;
                cursor: pointer;

                &.dark {
                    display: block;
                }
                &.light {
                    display: none;
                }

                &.open {
                    @include openPlus;
                }

                &:hover {
                    opacity: 0.75;
                }
            }
        }

        @include breakpointAbove('tablet') {

            &:hover {
                @include openTile;

                .title {
                    @include openTitle;
                }

                .text {
                    @include openText;
                }

                .plus-button-wrapper {
                    .plus {
                        @include openPlus;
                    }
                }
            }
        }
    }
</style>
